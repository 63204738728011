import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { rootApi } from "@/store/services/root-api";
import { authReducer } from "@/store/slices/auth.slice";

const appReducer = combineReducers({
    [rootApi.reducerPath]: rootApi.reducer,
    auth: authReducer,
});

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootApi.middleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Setup Store Listeners
// - https://redux-toolkit.js.org/rtk-query/api/setupListeners
// - A utility used to enable refetchOnFocus and refetchOnReconnect behaviors.
setupListeners(store.dispatch);
