import type { ReactNode } from "react";

import styles from "@/components/Layout/Layout.module.css";
import { Sidebar } from "@/components/Layout/Sidebar/Sidebar";

type Props = {
    children: ReactNode;
};

export const Layout = ({ children }: Props) => (
    <div className={styles.layout}>
        <Sidebar className={styles.sidebar} />
        {children}
    </div>
);
