import { Alert as AlertWDS, type AlertProps as AlertWDSProps } from "@datamole/wds-component-alert";
import { type ReactNode } from "react";

import { ErrorIcon, InfoIcon, SuccessIcon } from "./Icons";

const ICONS: { [key in NonNullable<AlertWDSProps["severity"]>]: ReactNode } = {
    error: <ErrorIcon />,
    warning: <ErrorIcon />,
    info: <InfoIcon />,
    success: <SuccessIcon />,
};

type Props = { title?: ReactNode; icon?: ReactNode } & AlertWDSProps;

const Alert = (props: Props) => {
    const { severity = "info", title, children, icon, ...otherProps } = props;

    const iconToShow = icon ?? ICONS[severity];

    return (
        <AlertWDS {...otherProps} severity={severity}>
            <AlertWDS.Icon>{iconToShow}</AlertWDS.Icon>
            <AlertWDS.Content>
                {title && <AlertWDS.Title>{title}</AlertWDS.Title>}
                <AlertWDS.Content>{children}</AlertWDS.Content>
            </AlertWDS.Content>
        </AlertWDS>
    );
};

export { Alert };
export type { Props as AlertProps };
