import { Input } from "@datamole/wds-component-input";
import { List } from "@datamole/wds-component-list";
import { Tooltip } from "@datamole/wds-component-tooltip";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";

import { AuthenticationGuard } from "@/components/AuthenticationGuard/AuthenticationGuard";
import { AuthenticationProviderWrapper } from "@/components/AuthenticationProviderWrapper/AuthenticationProviderWrapper";
import { ErrorFallback } from "@/components/ErrorFallback";
import { Layout } from "@/components/Layout";
import { pageAtRouteMap } from "@/pages";
import { routeNames, routes } from "@/routes";
import { store } from "@/store";

import "@/utils/dayjs.utils";
import "@datamole/wds-css-normalize";
import "@datamole/wds-css-theme-datamole";
import "@/styles/global.css";

/**
 * Create an array of objects with path and the component properties
 */
const pages = routeNames.map((routeName) => {
    const Component = pageAtRouteMap[routeName];
    return { path: routes[routeName].path, Component };
});

/**
 * Component representing the main application
 *
 * @returns rendered application
 */
const App = () => {
    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            {/* HOTFIX: To guarantee correct order of loaded WDS components styles,
                we need import 'Input' before 'Combobox' and 'List' before 'Menu'
            */}
            <Input style={{ display: "none" }} />
            <List style={{ display: "none" }} />
            <BrowserRouter>
                <AuthenticationProviderWrapper>
                    <Tooltip.Provider disableHoverableContent>
                        <Provider store={store}>
                            <Routes>
                                <Route
                                    element={
                                        <AuthenticationGuard>
                                            <Layout>
                                                {/* Used because app pages are lazy loaded */}
                                                <Suspense>
                                                    <Outlet />
                                                </Suspense>
                                            </Layout>
                                        </AuthenticationGuard>
                                    }
                                >
                                    {pages.map(({ path, Component }) => (
                                        <Route key={path} path={path} element={<Component />} />
                                    ))}
                                    <Route path="*" element={<Navigate to={routes.batches.path} replace />} />
                                </Route>
                            </Routes>
                        </Provider>
                    </Tooltip.Provider>
                </AuthenticationProviderWrapper>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export { App };
