import { type PublicClientApplication } from "@azure/msal-browser";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";

import { config } from "@/config";
import { AppDispatch } from "@/store";
import { clearAuth, setAuth } from "@/store/slices/auth.slice";
import { getAzureAdInstance } from "@/utils/auth.utils";

// Automatic re-authorization based on
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery

const mutex = new Mutex();

const extendBaseQueryWithReAuth = (
    baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
    return async (args, api, extraOptions) => {
        // If there is a refresh token in progress, wait for its finish.
        await mutex.waitForUnlock();

        let result = await baseQuery(args, api, extraOptions);
        if (result.error && result.error.status === 401) {
            // If unauthorized error is encountered, refresh the token and retry
            // the same request.
            await refreshToken(api.dispatch);
            result = await baseQuery(args, api, extraOptions);
        }

        return result;
    };
};

const refreshToken = async (dispatch: AppDispatch) => {
    // Only one task should try to reacquire the token (if branch). Others just wait until
    // the chosen task finishes (else branch).
    if (!mutex.isLocked()) {
        // It's important to have the `acquire` function be the first `await`
        // statement after `isLocked` check.
        const release = await mutex.acquire();

        const azureAdInstance = await getAzureAdInstance();

        try {
            const accessToken = await acquireAccessTokenSilent(azureAdInstance);

            if (accessToken) {
                dispatch(setAuth({ accessToken }));
            } else {
                // Do not attempt to log a user in outside the context of MsalProvider
                // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component
                dispatch(clearAuth());
            }
        } catch {
            dispatch(clearAuth());
        } finally {
            release();
        }
    } else {
        await mutex.waitForUnlock();
    }
};

const acquireAccessTokenSilent = async (azureAdInstance: PublicClientApplication) => {
    const activeAccount = azureAdInstance.getActiveAccount();
    const accounts = azureAdInstance.getAllAccounts();

    const request = {
        scopes: [config.AZURE_AD_AUTH_SCOPE],
        account: activeAccount ?? accounts.at(0),
    };

    const authResult = await azureAdInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

export { extendBaseQueryWithReAuth };
