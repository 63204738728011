import { z } from "zod";

import { BatchIcon, DeviceIcon, ModelIcon } from "@/components/Icons";
import { Route, SidebarRoute } from "@/routes/routes.types";

export const routes = {
    batches: {
        path: "/batches",
        Icon: <BatchIcon />,
        label: "Batches",
    },
    batchReport: {
        path: "/batches/:batchId/report",
        paramsSchema: z.object({
            batchId: z.string(),
        }),
    },
    models: {
        path: "/models",
        Icon: <ModelIcon />,
        label: "Models",
    },
    devices: {
        path: "/devices",
        Icon: <DeviceIcon />,
        label: "Devices",
    },
} satisfies Record<string, Route>;

const sidebarRouteNames = ["batches", "models", "devices"] satisfies (keyof typeof routes)[];
export const sidebarRoutes: SidebarRoute[] = sidebarRouteNames.map((name) => routes[name]);

export type Routes = typeof routes;
