import clsx from "clsx";
import type { HTMLAttributes } from "react";

import style from "./Spinner.module.css";

type Props = HTMLAttributes<HTMLDivElement> & {
    size?: "sm" | "md" | "lg";
};

const Spinner = (props: Props) => {
    const { size = "md", className, ...otherProps } = props;

    return <div {...otherProps} className={clsx(className, style.spinner)} data-size={size} />;
};

export { Spinner };
export type { Props as SpinnerProps };
