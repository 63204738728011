import styles from "./LoadingPage.module.css";

import { FreshAidLogoFull } from "@/components/Logo";
import { Spinner } from "@/components/Spinner/Spinner";

const LoadingPage = () => {
    return (
        <div className={styles.page}>
            <Spinner size="lg" />
            <div className={styles.container_logo}>
                <FreshAidLogoFull />
            </div>
        </div>
    );
};

export { LoadingPage };
