import { PublicClientApplication } from "@azure/msal-browser";
import { AzureAdAuthenticationProvider } from "@datamole/wds-azure-ad-auth";
import { ReactNode, useEffect, useState } from "react";

import { getAzureAdInstance } from "@/utils/auth.utils";

type Props = {
    children: ReactNode;
};

// TODO: Use updated AzureAdAuthenticationProvider after WEB-90 is done.
const AuthenticationProviderWrapper = ({ children }: Props) => {
    const [azureAdInstance, setAzureAdInstance] = useState<PublicClientApplication>();

    useEffect(() => {
        getAzureAdInstance()
            .then((instance) => setAzureAdInstance(instance))
            .catch((error) => console.error(`Failed to create Azure AD instance: ${error}`));
    }, []);

    if (!azureAdInstance) {
        return null;
    }

    return <AzureAdAuthenticationProvider instance={azureAdInstance}>{children}</AzureAdAuthenticationProvider>;
};

export { AuthenticationProviderWrapper };
