import { type ReactNode } from "react";

import { Alert } from "./Alert";

type Props = {
    className?: string;
    title?: string;
    children?: ReactNode;
};

// TODO: handle response errors
const Error = (props: Props) => {
    const {
        title = "Error Encountered",
        children = "Sorry, something went wrong. Please try again.",
        ...otherProps
    } = props;

    return (
        <Alert {...otherProps} severity="error" title={title}>
            {children}
        </Alert>
    );
};

export { Error };
export type { Props as ErrorProps };
