import { Link as RouterLink } from "react-router-dom";

import styles from "./ErrorPage.module.css";

import { Button } from "@/components/Button";
import { Error } from "@/components/Error";
import { HomeIcon } from "@/components/Icons";

const ErrorPage = () => {
    return (
        <div className={styles.page}>
            <div className={styles.content_container}>
                <Error />
                <Button as={RouterLink} to="/" size="md" variant="outline" startIcon={<HomeIcon />}>
                    Go to Home page
                </Button>
            </div>
        </div>
    );
};

export { ErrorPage };
