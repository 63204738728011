import { useAzureAdAuthentication } from "@datamole/wds-azure-ad-auth";
import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { NavLink } from "react-router-dom";

import { LogoutIcon } from "@/components/Icons";
import styles from "@/components/Layout/Sidebar/Sidebar.module.css";
import { FreshAidLogoFull } from "@/components/Logo";
import { sidebarRoutes } from "@/routes";

type Props = {
    className?: string;
};

const Sidebar = ({ className }: Props) => {
    const { user, triggerLogout } = useAzureAdAuthentication();

    return (
        <WdsSidebar className={className}>
            <WdsSidebar.Header className={styles.header}>
                <FreshAidLogoFull className={styles.logo} />
            </WdsSidebar.Header>
            <div className={styles.content_wrapper}>
                <WdsSidebar.Scrollable>
                    <WdsSidebar.Content className={styles.content}>
                        {sidebarRoutes.map((route) => (
                            <WdsSidebar.Item key={route.path} asChild>
                                <NavLink to={route.path}>
                                    <WdsSidebar.Item.Icon>{route.Icon}</WdsSidebar.Item.Icon>
                                    <WdsSidebar.Item.Content>{route.label}</WdsSidebar.Item.Content>
                                </NavLink>
                            </WdsSidebar.Item>
                        ))}
                    </WdsSidebar.Content>
                </WdsSidebar.Scrollable>

                <WdsSidebar.Content>
                    <WdsSidebar.Subtitle>{user?.name ?? user?.username}</WdsSidebar.Subtitle>
                    <WdsSidebar.Item asChild>
                        <button onClick={triggerLogout}>
                            <WdsSidebar.Item.Icon>
                                <LogoutIcon />
                            </WdsSidebar.Item.Icon>
                            <WdsSidebar.Item.Content>Log out</WdsSidebar.Item.Content>
                        </button>
                    </WdsSidebar.Item>
                </WdsSidebar.Content>
            </div>
        </WdsSidebar>
    );
};

export { Sidebar };
