import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export * from "@/pages/pages.types";

export const BatchesPage = lazy(() => import("@/pages/BatchesPage"));
export const BatchReportPage = lazy(() => import("@/pages/BatchReportPage"));
export const DevicesPage = lazy(() => import("@/pages/DevicesPage"));
export const ModelsPage = lazy(() => import("@/pages/ModelsPage"));

export { LoadingPage } from "@/pages/LoadingPage";
export { ErrorPage } from "@/pages/ErrorPage";

export const pageAtRouteMap = {
    batches: BatchesPage,
    batchReport: BatchReportPage,
    models: ModelsPage,
    devices: DevicesPage,
} satisfies Partial<PageAtRouteMap>;
