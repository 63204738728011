import {
    AzureAdAuthenticationTemplate,
    AzureAdInteractionType,
    useAzureAdAuthentication,
} from "@datamole/wds-azure-ad-auth";
import { ReactNode, useEffect } from "react";

import { config } from "@/config";
import { LoadingPage, ErrorPage } from "@/pages";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectAccessToken, setAuth } from "@/store/slices/auth.slice";

/**
 * Set preferred interaction type for token acquisition
 * Choose Redirect or Popup
 */
const INTERACTION_TYPE = AzureAdInteractionType.Redirect;

type Props = {
    children: ReactNode;
};

export const AuthenticationGuard = ({ children }: Props) => {
    const accessToken = useAppSelector(selectAccessToken);
    const dispatch = useAppDispatch();

    const { isInteractionInProgress, acquireAccessToken } = useAzureAdAuthentication({
        interactionType: INTERACTION_TYPE,
    });

    useEffect(() => {
        /**
         * If user is already logged in or interaction is in progress, do nothing
         */
        if (accessToken || isInteractionInProgress) return;

        /**
         * Acquire token otherwise for the scope specified in the configuration
         * Token will be acquired silently if user is already logged in and passed to the callback function
         */
        acquireAccessToken({
            scopes: [config.AZURE_AD_AUTH_SCOPE],
            persistTokenCallback: (token) => dispatch(setAuth({ accessToken: token })),
        });
    }, [isInteractionInProgress, accessToken, acquireAccessToken, dispatch]);

    return (
        <AzureAdAuthenticationTemplate
            interactionType={INTERACTION_TYPE}
            /**
             * Component to render if any error occurs, i.e. if user cancels the flow
             * You typically want to init login again, redirect to login page, etc.
             *  - props has type AzureAdAuthenticationResult from "@datamole/wds-azure-ad-auth"
             */
            errorComponent={ErrorPage}
            /**
             * Component to render while:
             *  - token is being acquired
             *  - login is in progress
             *  - logout is in progress
             */
            loadingComponent={LoadingPage}
        >
            {children}
        </AzureAdAuthenticationTemplate>
    );
};
