import { Button as WDSButton, ButtonProps as WDSButtonProps } from "@datamole/wds-component-button";
import { forwardRefPolymorphic } from "@datamole/wds-std";
import { type ElementType, type ForwardedRef } from "react";

type Props<TAs extends ElementType = "button"> = {
    startIcon?: React.ReactNode;
} & WDSButtonProps<TAs>;

const Button = <TAs extends ElementType = "button">(props: Props<TAs>, ref: ForwardedRef<unknown>) => {
    const { children, startIcon, ...otherProps } = props;

    return (
        <WDSButton {...otherProps} ref={ref}>
            {startIcon && <WDSButton.Icon>{startIcon}</WDSButton.Icon>}
            <WDSButton.Content>{children}</WDSButton.Content>
        </WDSButton>
    );
};
const ButtonWithRefForwarded = forwardRefPolymorphic(Button);

export { ButtonWithRefForwarded as Button };
export type { Props as ButtonProps };
