import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { config } from "@/config";
import type { RootState } from "@/store";
import { extendBaseQueryWithReAuth } from "@/store/interceptors/refresh-token.interceptor";

const baseQuery = extendBaseQueryWithReAuth(
    fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders: (headers, api) => {
            const state = api.getState() as RootState;
            const accessToken = state.auth.accessToken;
            if (accessToken) {
                headers.set("authorization", `Bearer ${accessToken}`);
            }
            return headers;
        },
    }),
);

export const rootApi = createApi({
    baseQuery,
    endpoints: () => ({}),
});
