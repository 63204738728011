import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "@/App";

// eslint-disable-next-line no-console
console.log(`FreshAid v${APP_VERSION} on ${import.meta.env.MODE} environment`);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
