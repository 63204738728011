import { useParams as useParamsRRD } from "react-router-dom";
import { z } from "zod";

import { routes } from "@/routes/routes";
import { RouteName, RouteWithParamsSchema } from "@/routes/routes.types";

/**
 * Array of routeNames from routes object
 */
export const routeNames = Object.keys(routes) as RouteName[];

/**
 * Extended useParams from 'react-router-dom'
 *  - parses params with zod schema
 */
export const useParams = <const T extends RouteWithParamsSchema>(route: T): z.infer<T["paramsSchema"]> => {
    const params = useParamsRRD();
    const parsedParams = route.paramsSchema.parse(params);
    return parsedParams;
};
