import { PublicClientApplication } from "@azure/msal-browser";
import { createAzureAdInstance } from "@datamole/wds-azure-ad-auth";

import { config } from "@/config";

let azureAdInstance: PublicClientApplication;

/**
 * Use this function to get the Azure AD instance outside of React components,
 * otherwise use the `useAzureAdAuthentication` hook instead.
 */
const getAzureAdInstance = async (): Promise<PublicClientApplication> => {
    if (!azureAdInstance) {
        azureAdInstance = await createAzureAdInstance({
            authorityUrl: config.AZURE_AD_AUTH_AUTHORITY_URL,
            clientId: config.AZURE_AD_AUTH_CLIENT_ID,
            redirectUrl: config.AZURE_AD_AUTH_REDIRECT_URL,
            postLogoutRedirectUrl: config.AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL,
        });
    }

    return azureAdInstance;
};

export { getAzureAdInstance };
