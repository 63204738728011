import {
    faLayerGroup,
    faMicrochipAi,
    faUpload,
    faServer,
    faCircleSmall,
    faCircleExclamation,
    faCircleInfo,
    faCircleCheck,
    faArrowRightFromBracket,
    faXmark,
    faHouse,
    faEllipsisStrokeVertical,
    faArrowUpRightFromSquare,
    faPenToSquare,
    faEye,
    faAngleDown,
    faCheck,
    faPaperPlane,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type IconProps = Omit<FontAwesomeIconProps, "icon">;

export const BatchIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faLayerGroup} />;
export const DeviceIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faServer} />;
export const ModelIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faMicrochipAi} />;
export const UploadIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faUpload} />;
export const CircleIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleSmall} />;
export const ErrorIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleExclamation} />;
export const InfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleInfo} />;
export const SuccessIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleCheck} />;
export const LogoutIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faArrowRightFromBracket} />;
export const CloseIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faXmark} />;
export const HomeIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faHouse} />;
export const MoreIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faEllipsisStrokeVertical} />;
export const OpenInNewTabIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faArrowUpRightFromSquare} />;
export const EditIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faPenToSquare} />;
export const ViewIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faEye} />;
export const ExpandIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faAngleDown} />;
export const CheckIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCheck} />;
export const SubmitIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faPaperPlane} />;
